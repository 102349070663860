import React from "react";
import Control from "../../common/Control";
import PostalCode from "../PostalCode";
import DigitControl from "../DigitControl";
import GroupedDigitControl from "../GroupedDigitControl";

const AnswerInterpretor = ({
  question,
  inputTypes,
  updateAnswer,
  answers,
  k,
  errors,
}) => {
  var content = <></>;
  var questionType = inputTypes.find((i) => i.id == question.type).type;

  if (questionType == "postalCode") {
    content = (
      <PostalCode
        value={answers.find((a) => a.question == question.id).value}
        updateData={updateAnswer}
        name={question.id}
        error={errors}
      />
    );
  } else if (questionType == "nir") {
    content = (
      <>
        <div className="row m-0">
          <div className="col-12 col-md-6 col-lg-6">
            <img
              style={{ maxHeight: 196 }}
              className="img-fluid mb-3"
              src="/assets/carte-vitale.png"
              alt="spécimen de carte vitale"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <p className="text-primary">
              Votre numéro de sécurité sociale figure sur la face recto de votre
              carte vitale (cf image).
            </p>
          </div>
        </div>

        <GroupedDigitControl
          name={question.id}
          value={answers.find((a) => a.question == question.id).value}
          separation={[1, 2, 2, 2, 3, 3, 2]}
          separator={"   "}
          change={updateAnswer}
          autoFocus={true}
        />
        <span className="text-danger">{errors[question.id]}</span>
      </>
    );
  } else {
    content = (
      <Control
        label={""}
        autoFocus={true}
        name={question.id}
        id={k}
        type={questionType}
        datas={question.answers}
        dataIndex={"value"}
        dataLabel={"label"}
        inputParams={question.inputParams}
        change={updateAnswer}
        value={
          answers.find((a) => a.question == question.id).value ==
          "useAlreadyExistingEmail@cafreunion.re"
            ? ""
            : answers.find((a) => a.question == question.id).value
        }
        error={errors}
      />
    );
  }

  return content;
};

export default AnswerInterpretor;
