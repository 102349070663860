import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import FormGenerator from "../../common/FormGenerator/FormGenerator";
import { api_url } from "../../../config";
import FormAccount from "./FormAccount";
import FormRdv from "./FormRdv";
import ProgressSection from "./ProgressSection";
import tools from "../../../helpers/tools";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";
import FormViewerPdf from "./FormViewerPdf";

const FormRDSP = (props) => {
  const [isAccountFinished, setIsAccountFinished] = useState(false);
  const [isDspFinished, setIsDspFinished] = useState(false);
  const [isRdvFinished, setIsRdvFinished] = useState(false);

  const [userCreated, setUserCreated] = useState(false);
  const [formData, setFormData] = useState(false);
  const [userData, setUserData] = useState(false);
  const [hasDroit, setHasDroit] = useState(false);
  const [checkHasRdspDone, setCheckHasRdspDone] = useState(false);
  const [checkHasRightDone, setCheckHasRightDone] = useState(false);

  const [showRedirectMsg, setShowRedirectMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [origin, setOrigin] = useState(false);
  const [rdspForm, setRdspForm] = useState(false);
  const [showDl, setShowDl] = useState(false);
  const [isConjoint, setIsConjoint] = useState(false)

  function reset() {
    setIsDspFinished(false);
    setIsAccountFinished(false);
    setIsRdvFinished(false);
    //tools.saveState("FormRDSP", "answers", false);
    //tools.saveState("FormRDSP", "step", 0);
  }

  useEffect(() => {
    setShowRedirectMsg(props.location.search == "?redirectfrombo");
    var qs = window.location.search.substring(1);
    qs = tools.parse_query_string(qs);

    if (qs.o) {
      setOrigin(qs.o);
    }
    if (qs.co) {
      setIsConjoint(true)
    }
  }, [props.location.search]);

  useEffect(() => {
    if (isAccountFinished && userData.id && !checkHasRdspDone) {
      //TODO - REMPLACER PAR LE BON CALL
      setCheckHasRdspDone(true);
      setIsDspFinished(!userData.rdspAvailable);
    }
  }, [isAccountFinished, userData]);

  useEffect(() => {
    if (isDspFinished && userData.id && !checkHasRightDone) {
      setIsLoading(true);
      dataService.get(
        "recipients/" + userData.id + "/rights",
        (datas) => {
          setCheckHasRightDone(true);
          setHasDroit(datas.isRightsOpen);
        },
        (errors) => {},
        () => {
          setIsLoading(false);
        }
      );
    }
  }, [isDspFinished, userData]);

  return (
    <>
      <ProgressSection
        sections={[
          {
            title: "Identification",
            active: !isAccountFinished,
          },
          {
            title: "RDSP",
            active: !isDspFinished && isAccountFinished,
          },
          {
            title: "Récapitulatif",
            active: isAccountFinished,
          },
        ]}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {showRedirectMsg && !isAccountFinished && (
            <p className="alert alert-primary mt-3 w-75 mx-auto animated fadeInDown">
              Vous possédez bien un compte ESPOAR mais vous n'avez pas encore
              complété le formulaire RDSP. Veuillez suivre les étapes
              ci-dessous.
            </p>
          )}
          {!isAccountFinished ? (
            <Page title={"Identification"} containerClassname="">
              <FormAccount
                formData={formData}
                isAccountFinished={isAccountFinished}
                setIsAccountFinished={setIsAccountFinished}
                setUserData={setUserData}
                setUserCreated={setUserCreated}
                origin={origin}
              />
            </Page>
          ) : !isDspFinished ? (
            <Page
              title="Recueil des données socio-professionnelles"
              containerClassname=""
            >
              <FormGenerator
                formDataUrl={api_url + "rdsp/"}
                isDspFinished={isDspFinished}
                setIsDspFinished={setIsDspFinished}
                setFormData={setFormData}
                withContinue={false}
                specialCaseRDSP={true}
                additionalDataToSave={{ user: userData.id }}
                setRdspForm={setRdspForm}
              />
            </Page>
          ) : !isRdvFinished ? (
            <Page
              title="Récapitulatif"
              containerClassname="animated fadeInRight faster"
            >
              <FormRdv
                isRdvFinished={isRdvFinished}
                setIsRdvFinished={setIsRdvFinished}
                rdspId={formData.id}
                reset={reset}
                userData={userData}
                userCreated={userCreated}
                formData={formData}
                      hasDroit={hasDroit}
                      isConjoint={isConjoint}
              />
              {formData && (
                <div className="row">
                  <div className="col-12 text-center mt-3">
                    {" "}
                    <button
                      className="btn btn-default"
                      onClick={() => setShowDl(true)}
                    >
                      Télécharger mes réponses au formulaire RDSP
                    </button>
                    {showDl && (
                      <FormViewerPdf
                        form={rdspForm}
                        answers={formData.rdsp}
                        recipient={userData}
                      />
                    )}{" "}
                  </div>
                </div>
              )}
            </Page>
          ) : (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center font-weight-bolder text-primary">
              Terminé
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FormRDSP;
