import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.scss";
import "./animate.css";
import "./buttons.scss";
import "./color-theme.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";

// ReactDOM.render(
//   <Store rootReducer={rootReducer}>
//     <App />
//   </Store>,
//   document.getElementById("root")
// );

const root = createRoot(document.getElementById("root"));
root.render(
  <Store rootReducer={rootReducer}>
    <App />
  </Store>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
